<template>
    <div class="flexPage themeColor">
        <navbar :params="pageInfo" />
        <div class="title">Function</div>
        <div class="create">{{ $t('other.translate6') }}</div>
        <div class="createC">{{ $t('other.translate141') }}</div>
        <el-input v-model="formValidate.data.username.value" :placeholder="$t('other.translate137')" style="margin-top: 24px"></el-input>
        <el-input v-model="formValidate.data.password.value" :placeholder="$t('other.translate138')" type="password"
            :show-password="true"></el-input>
        <div class="btn" @click="login()">{{ $t('other.translate6') }}</div>
        <div class="already">
            <span>{{ $t('other.translate139') }}</span>
        </div>
        <div class="btnWeb" @click="goLogin">{{ $t('other.translate140') }}</div>
        <div class="already1">{{ $t('other.translate151') }}! <span @click="goRegister">{{ $t('other.translate150') }}</span></div>
		<div class="footer1">{{ $t('other.translate146') }} <span style="color: #000"> {{ $t('other.translate147') }} </span> {{ $t('other.translate149') }} <span style="color: #000"> {{ $t('other.translate148') }}</span>
		</div>
        <!-- 用户登录 -->
        <!-- <div class="signForm">
                <van-field class="custom-input" type="text" :border="false" v-model="formValidate.data.username.value"
                    maxlength="64" :placeholder="$t('form.username')">
                </van-field>
                <van-field class="mt4 custom-input" :type="eyesOn ? 'text' : 'password'"
                    v-model="formValidate.data.password.value" :placeholder="$t('form.password')" maxlength="64">
                    <div slot="right-icon" @click="eyesOn = !eyesOn" class="ml3">
                        <van-icon :name="eyesOn ? 'eye-o' : 'closed-eye'" size="20" />
                    </div>
                </van-field>
                <div class="pt8">
                    <van-button round :class="!submitStatus ? 'active' : ''" size="large" @click="login()">{{
                    $t("form.signin.submit") }}</van-button>
                </div>
                <p class="flexBothSidesVertical">
                    <span @click="$router.push('/signup')">{{ $t('form.signin.register') }}</span>
                    <span class="forgetPsw" @click="$router.push('/forget')">{{ $t('form.signin.forget') }}</span>
                </p>
            </div> -->
    </div>
</template>
<script>
import navbar from '@/views/modules/navbar.vue';
export default {
    components: { navbar },
    data() {
        return {
            cur: 0,
            eyesOn: false,
            submitStatus: false, // 是否校验成功
            formValidate: {
                submiting: false,
                validateAll: false,
                data: {
                    username: {
                        type: "username",
                        value: "",
                        isDefault: true,
                        isError: false,
                    },
                    password: {
                        type: "password",
                        value: "",
                        isDefault: true,
                        isError: false,
                    },
                },
            },
            initOk: false,
            geetest: null, // 极验对象
            dto: {},
            pageInfo: {
                customTitle: '',
                border: false,
                navbarStyle: 'normal'
            },
        };
    },
    created() {

        let that = this;
        // 初始化极验
        // that.$mts.initGt(
        //     (geetest) => {
        //         that.callback(geetest); // 加载完成
        //     },
        //     (res) => {
        //         // 参数回调
        //         that.dto.gt_server_status = res.gt_server_status;
        //         that.dto.txHash = res.txHash;
        //     }
        // );
    },
    methods: {
        callback(geetest) {
            var that = this;
            that.geetest = geetest;
            geetest
                .onReady(function () {
                    //验证码ready之后才能调用verify方法显示验证码
                    that.initOk = true;
                })
                .onSuccess(function () {
                    that.login();
                })
                .onError(function () {
                    // 人机验证初始化失败
                    that.$toast(that.$t("validate.robot"));
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                });
        },
        login() {
            // 开始登陆
            var that = this;
            // if (
            //     !that.$vld.checkValue(that.formValidate.data.username.value, "username")
            // ) {
            //     that.$toast(that.$t("validate.signup.usernameError"));
            //     return;
            // }

            // if (
            //     !that.$vld.checkValue(that.formValidate.data.password.value, "password")
            // ) {
            //     that.$toast(that.$t("validate.public.passwordError"));
            //     return;
            // }
            // this.submitStatus = true;
            // //let validate = that.geetest.getValidate();
            // that.$data.formValidate.submiting = true;
            // that.dto.type = that.$vld.checkValue(that.formValidate.data.username.value, 'email') ? "email" : "mobile";

            let params = {
                ticket: "",
                randStr: "",
                username: that.formValidate.data.username.value,
                password: that.formValidate.data.password.value,
            };

            that.$http.post(that.host + "/uc/login", params).then((response) => {
                // 发送成功
                if (response.data.code == 0) {
                    that.$toast(that.$t("validate.signin.success"));
                    that.$store.commit('setLoginStatus', true);
                    localStorage.setItem("token", response.data.data.token);
                    localStorage.setItem("user", JSON.stringify(response.data.data));
                    this.$store.commit('setMember', response.body.data);
                    that.$data.formValidate.submiting = false;
                    if (that.$route.query.path) {
                        that.$router.push({ path: that.$route.query.path });
                    } else {
                        that.$router.push({ name: "home" });
                    }
                } else {
                    that.$toast(response.data.message);
                    that.$data.formValidate.submiting = false;
                }
            });
        },
        goLogin() {
			this.$mts.goto('web')
		},
        goRegister() {
			this.$mts.goto('login')
		}
    },
};
</script>
<style scoped>
.footer1 {
	width: 327px;
	margin: 0 auto;
	font-family: Inter;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	text-align: center;
	color: #828282;
	margin-top: 24px;
}

.already1 {
	width: 100%;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 21px;
	text-align: center;
	margin-top: 135px;
}
.footer {
    width: 327px;
    margin: 0 auto;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #828282;
    margin-top: 24px;
}

.already {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    margin: 24px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 330px;
    /* 根据需要调整线的长度 */
    height: 1px;
    /* 线的高度，可以根据需要调整 */
    background-color: #E6E6E6;
    /* 线的颜色，可以根据需要调整 */
    margin: 20px auto;
    /* 给线条一些外边距，使其居中显示 */
}


.btn {
    width: 327px;
    height: 40px;
    background: #000000;
    border-radius: 8px;
    margin: 0 auto;
    margin-top: 16px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    text-align: center;
    color: #fff
}

.btnWeb {
    width: 327px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 8px;
    margin: 0 auto;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    text-align: center;
    color: #000
}

::v-deep .el-input__inner {
    border-radius: 8px;
}

::v-deep .el-input {
    width: 327px;
    height: 40px;
    border-radius: 8px;
    margin: 0 auto;
    margin-top: 8px;
}

.createC {
    width: 100%;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    text-align: center;

}

.create {
    width: 100%;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-top: 112px;

}

.title {
    width: 100%;
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}
</style>
